import React from "react";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { ScrollingProvider } from "react-scroll-section";
import { ParallaxProvider } from "react-scroll-parallax";

import Home from "./home/pages/Home";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import Footer from "./shared/components/UIElements/Footer";

function App() {
  return (
    <BrowserRouter>
      <ParallaxProvider>
        <ScrollingProvider offset={-100}>
          <MainNavigation />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
          <Footer />
        </ScrollingProvider>
      </ParallaxProvider>
    </BrowserRouter>
  );
}

export default App;
