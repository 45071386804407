import React, { useEffect, useRef } from "react";

import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Typed from "typed.js";

import ImgWithFallback from "./../../shared/components/UIElements/ImgWithFallback";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "./css/Top.css";
import logoImage from "../../shared/utils/images/logo.png";
import logoImageWEBP from "../../shared/utils/images/logo.webp";
import logoImageAVIF from "../../shared/utils/images/logo.avif";

import slider1 from "../../shared/utils/images/top-slide-1.jpeg";
import slider1WEBP from "../../shared/utils/images/top-slide-1.webp";
import slider1AVIF from "../../shared/utils/images/top-slide-1.avif";
import slider2 from "../../shared/utils/images/top-slide-2.jpeg";
import slider2WEBP from "../../shared/utils/images/top-slide-2.webp";
import slider2AVIF from "../../shared/utils/images/top-slide-2.avif";
import slider3 from "../../shared/utils/images/top-slide-3.jpeg";
import slider3WEBP from "../../shared/utils/images/top-slide-3.webp";
import slider3AVIF from "../../shared/utils/images/top-slide-3.avif";
import slider4 from "../../shared/utils/images/top-slide-4.jpeg";
import slider4WEBP from "../../shared/utils/images/top-slide-4.webp";
import slider4AVIF from "../../shared/utils/images/top-slide-4.avif";
import slider5 from "../../shared/utils/images/top-slide-5.jpeg";
import slider5WEBP from "../../shared/utils/images/top-slide-5.webp";
import slider5AVIF from "../../shared/utils/images/top-slide-5.avif";
import { Container } from "react-bootstrap";

SwiperCore.use([Autoplay, EffectFade]);

const Top = () => {
  const typerTextElement = useRef(null);

  useEffect(() => {
    const typed = new Typed(typerTextElement.current, {
      strings: ["Sustainable Pharma Solutions for the World."],
      startDelay: 300,
      typeSpeed: 50,
      backSpeed: 100,
      backDelay: 100,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <React.Fragment>
      <Swiper
        centeredSlides={true}
        effect="fade"
        autoplay={{ delay: 5000 }}
        slidesPerView={1}
        simulateTouch={false}
        allowTouchMove={false}
        speed={500}
        loop={true}
        className="home-top__carousel"
      >
        {/* <Container> */}
        <div className="home-top__content-wrapper">
          <div className="home-top__content-container">
            {/* <img
              className="home-top__logo"
              src={logoImage}
              data-aos="flip-up"
            /> */}
            <ImgWithFallback
              className="home-top__logo"
              src={logoImageWEBP}
              avifSrc={logoImageAVIF}
              fallback={logoImage}
              alt="Thabbat Logo"
              data-aos="flip-up"
              width={486}
              height={220}
            />
            <h2
              className="home-top__text"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span ref={typerTextElement}></span>
            </h2>
          </div>
        </div>
        {/* </Container> */}
        <SwiperSlide className="home-top__header-slider-item">
          <div className="home-top__header-slider-content-container">
            <ImgWithFallback
              className="home-top__header-banner"
              fallback={slider1}
              avifSrc={slider1AVIF}
              src={slider1WEBP}
              alt="Thabbat banner 1"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="home-top__header-slider-item">
          <div className="home-top__header-slider-content-container">
            <ImgWithFallback
              className="home-top__header-banner"
              fallback={slider2}
              avifSrc={slider2AVIF}
              src={slider2WEBP}
              alt="Thabbat banner 2"
              width={1920}
              height={1080}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="home-top__header-slider-item">
          <div className="home-top__header-slider-content-container">
            <ImgWithFallback
              className="home-top__header-banner"
              fallback={slider3}
              avifSrc={slider3AVIF}
              src={slider3WEBP}
              alt="Thabbat banner 3"
              width={1920}
              height={1080}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="home-top__header-slider-item">
          <div className="home-top__header-slider-content-container">
            <ImgWithFallback
              className="home-top__header-banner"
              fallback={slider4}
              avifSrc={slider4AVIF}
              src={slider4WEBP}
              alt="Thabbat banner 4"
              width={1920}
              height={1080}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="home-top__header-slider-item">
          <div className="home-top__header-slider-content-container">
            <ImgWithFallback
              className="home-top__header-banner"
              fallback={slider5}
              avifSrc={slider5AVIF}
              src={slider5WEBP}
              alt="Thabbat banner 5"
              width={1920}
              height={1080}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </React.Fragment>
  );
};

export default Top;
