import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import ServiceCard from "../../shared/components/UIElements/ServiceCard";

import bg from "../../shared/utils/images/bg.png";
import bgWEBP from "../../shared/utils/images/bg.webp";
import bgAVIF from "../../shared/utils/images/bg.avif";
import cardBG1 from "../../shared/utils/images/services-bg-1.png";
import cardBG1WEBP from "../../shared/utils/images/services-bg-1.webp";
import cardBG1AVIF from "../../shared/utils/images/services-bg-1.avif";
import cardBG2 from "../../shared/utils/images/services-bg-2.png";
import cardBG2WEBP from "../../shared/utils/images/services-bg-2.webp";
import cardBG2AVIF from "../../shared/utils/images/services-bg-2.avif";
import cardBG3 from "../../shared/utils/images/services-bg-3.png";
import cardBG3WEBP from "../../shared/utils/images/services-bg-3.webp";
import cardBG3AVIF from "../../shared/utils/images/services-bg-3.avif";
import cardBG4 from "../../shared/utils/images/services-bg-4.png";
import cardBG4WEBP from "../../shared/utils/images/services-bg-4.webp";
import cardBG4AVIF from "../../shared/utils/images/services-bg-4.avif";
import icon1 from "../../shared/utils/images/icons/services-icon-1.svg";
import icon2 from "../../shared/utils/images/icons/services-icon-2.svg";
import icon3 from "../../shared/utils/images/icons/services-icon-3.svg";
import icon4 from "../../shared/utils/images/icons/services-icon-4.svg";

import "./css/Services.css";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

export default () => {
  return (
    <div className="home-services__section-container ">
      {/* <img src={bg} className="home-services__bg-img" /> */}
      <ImgWithFallback
        className="home-services__bg-img"
        width={1920}
        height={1080}
        src={bgWEBP}
        avifSrc={bgAVIF}
        fallback={bg}
      />
      <Container className="home-services__container">
        <div className="home-services__wrapper">
          <h2 data-aos="fade-up">Supportive Strengths</h2>

          <Row className="home-services__row">
            <ServiceCard
              itemNumber={1}
              bgIMG={cardBG1}
              bgIMGWEBP={cardBG1WEBP}
              bgIMGAVIF={cardBG1AVIF}
              className="home-services__card-1"
              icon={icon1}
              title="Consultation"
              points={
                <React.Fragment>
                  <li>New access to the Saudi market and GGC.</li>
                  <li>Representations and Meetings with the authorities.</li>
                  <li>
                    Integrated marketing communication and promotion in the
                    professional environment and among KOL of the product group.
                  </li>
                  <li>Manufacturing and tech transfer.</li>
                </React.Fragment>
              }
            />

            <ServiceCard
              itemNumber={2}
              bgIMG={cardBG2}
              bgIMGWEBP={cardBG2WEBP}
              bgIMGAVIF={cardBG2AVIF}
              className="home-services__card-2"
              icon={icon2}
              title="Project Management"
              points={
                <React.Fragment>
                  <li>
                    Management projects (Acquisitions, licensing, CMO, tech
                    transfer)
                  </li>
                  <li>Product Development</li>
                </React.Fragment>
              }
            />

            <ServiceCard
              itemNumber={3}
              bgIMG={cardBG3}
              bgIMGWEBP={cardBG3WEBP}
              bgIMGAVIF={cardBG3AVIF}
              className="home-services__card-2"
              icon={icon3}
              title="Regulatory Services"
              points={
                <React.Fragment>
                  <li>Marketing Applications</li>
                  <li>Product Lifecycle Management.</li>
                  <li>Regulatory strategy design and implementation.</li>
                  <li>
                    End to end support from the manufacturer to the end
                    consumer.
                  </li>
                  <li>Building Up and create Product dossiers.</li>
                  <li>
                    New registrations (Human Drug, biological, biosimilar,
                    Herbals, Medical Device, Cosmetics, and Food Supplements)
                  </li>
                  <li>Local serialization and aggregation.</li>
                  <li>Products classification.</li>
                  <li>
                    Compliance review and creation of SPCs, leaflets (PIL),
                    labels, and artworks.
                  </li>
                </React.Fragment>
              }
            />

            <ServiceCard
              itemNumber={4}
              bgIMG={cardBG4}
              bgIMGWEBP={cardBG4WEBP}
              bgIMGAVIF={cardBG4AVIF}
              className="home-services__card-2"
              icon={icon4}
              title="Pharmacovigilance"
              points={
                <React.Fragment>
                  <li>
                    Full outsource services for Pharmacovigilance compliance
                    QPPV services.
                  </li>
                  <li>
                    Building PV systems and documentation (PSMF, PSSF, RMP,
                    PSUR/PEBRER, PV SOPs).
                  </li>
                  <li>Pharmacovigilance Audit.</li>
                  <li>
                    Implementing risk management strategies to minimize risks
                    associated with medicines use
                  </li>
                  <li> Communication of any risk to the public and HCPs.</li>
                  <li>
                    Assessing the effectiveness of pharmacovigilance activities.
                  </li>
                </React.Fragment>
              }
            />
          </Row>
        </div>
      </Container>
    </div>
  );
};
