import React from "react";

import icon from "../../utils/images/icons/list-icon.svg";

import "./css/LoadingSpinner.css";

const LoadingSpinner = (props) => {
  return (
    <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
      <img src={icon} className="loading-spinner__icon" />
    </div>
  );
};

export default LoadingSpinner;
