import React from "react";
import { Parallax } from "react-scroll-parallax";

import { Col, Container, Row } from "react-bootstrap";
import QuotationIcon from "../../shared/utils/icons/QuotationIcon";

import bg from "../../shared/utils/images/successful-partnerships-bg.jpg";
import bgWEBP from "../../shared/utils/images/successful-partnerships-bg.webp";
import bgAVIF from "../../shared/utils/images/successful-partnerships-bg.avif";
import mapImage from "../../shared/utils/images/map.png";
import mapImageWEBP from "../../shared/utils/images/map.webp";
import mapImageAVIF from "../../shared/utils/images/map.avif";

import "./css/SuccessfulPartnership.css";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

export default () => {
  return (
    <div className="home-s-partnership__section-container ">
      {/* <img src={bg} className="home-s-partnership__bg-img" /> */}
      <ImgWithFallback
        className="home-s-partnership__bg-img"
        width={2249}
        height={1265}
        src={bgWEBP}
        avifSrc={bgAVIF}
        fallback={bg}
      />
      <Container className="home-s-partnership__container">
        <Row className="home-s-partnership__row">
          <Col xs={12} md={6}>
            <Parallax translateY={["50", "-50"]}>
              {/* <img
              src={mapImage}
              className="home-s-partnership__image"
              data-aos="fade-up"
              data-aos-delay="300"
            /> */}
              <ImgWithFallback
                className="home-s-partnership__image"
                width={613}
                height={296}
                src={mapImageWEBP}
                avifSrc={mapImageAVIF}
                fallback={mapImage}
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </Parallax>
          </Col>

          <Col xs={12} md={6}>
            <Parallax translateY={["-50", "50"]}>
              <h1 className="home-s-partnership__heading" data-aos="fade-up">
                Successful Partnerships
              </h1>
              <p
                className="home-s-partnership__text"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Our goal is to create long-term mutually beneficial
                relationships with our commercial partners
              </p>

              {/* <i className="home-s-partnership__quote-icon fa-sharp fa-solid fa-quote-right"></i> */}
              <div data-aos="fade-up" data-aos-delay="200">
                <QuotationIcon color="#fff" />
                <p className="home-s-partnership__text">
                  We are committed to partnerships that help speed up market
                  access of innovative new medicines within our therapy area
                  portfolio, and that ensure both parties are receiving the
                  greatest possible value from the relationship. Integrating
                  strategic, operational and cultural fit with effective
                  governance helps to forge a strong relationship at all
                  levels.”
                </p>
              </div>
            </Parallax>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
