import React from "react";
import {Parallax} from "react-scroll-parallax";

import "./css/About.css";
import bg from "../../shared/utils/images/about-bg.jpg";
import bgWEBP from "../../shared/utils/images/about-bg.webp";
import bgِAVIF from "../../shared/utils/images/about-bg.avif";
import earthIMG from "../../shared/utils/images/earth-cropped.png";
import earthIMGWEBP from "../../shared/utils/images/earth-cropped.webp";
import earthIMGAVIF from "../../shared/utils/images/earth-cropped.avif";

import { Col, Container, Row } from "react-bootstrap";
import QuotationIcon from "../../shared/utils/icons/QuotationIcon";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

const About = () => {
  return (
    <div className="home-about__section-container ">
      {/* <img src={bg} className="home-about__bg-image" /> */}
      <ImgWithFallback
        className="home-about__bg-image"
        width={1920}
        height={1080}
        src={bgWEBP}
        avifSrc={bgِAVIF}
        fallback={bg}
      />
      <Container fluid className="home-about__container">
        <Row className="home-about__row">
          <Col xs={1}></Col>
          <Col xs={12} md={6} className="home-about__text-col">
          <Parallax translateY={["80", "-80"]}>
            <h1 className="home-about__heading" data-aos="fade-up">
              Developing & Delivering High-Specialty Products for security of
              supply worldwide
            </h1>
            <QuotationIcon
              className="home-about__quote-icon"
              data-aos="fade-up"
              data-aos-delay="150"
            />
            {/* <i className="home-about__quote-icon fa-sharp fa-solid fa-quote-right"></i> */}
            <p
              className="home-about__text"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              We are a highly specialized pharmaceutical company that delivers
              niche products and innovative drugs solutions to patients and
              healthcare specialists alike in Saudi, MENA and world wide.
            </p>

            <p
              className="home-about__text"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              THABBAT works in partnership with its Alliances to maximize
              opportunities and meet key milestones on time and on budget. We
              are committed to finding innovative solutions to increasing health
              needs in different patient populations based on solid and
              extensive life-sciences, medical, regulatory and commercial
              expertise and capabilities.
            </p>
            </Parallax>
          </Col>
          <Col xs={12} md={5}>
          <Parallax translateY={["-80", "80"]}>
            <ImgWithFallback
              className="home-about__earth-img"
              width={648}
              height={632}
              src={earthIMGWEBP}
              avifSrc={earthIMGAVIF}
              fallback={earthIMG}
              data-aos="fade-up"
              data-aos-delay="300"
            />
            </Parallax>
            {/* <img
              src={earthIMG}
              className="home-about__earth-img"
              data-aos="fade-up"
              data-aos-delay="400"
            /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
