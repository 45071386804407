import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Col, Container, Row } from "react-bootstrap";

import bg from "../../shared/utils/images/focus-bg.png";
import bgWEBP from "../../shared/utils/images/focus-bg.webp";
import bgAVIF from "../../shared/utils/images/focus-bg.avif";
import squareIcon1 from "../../shared/utils/images/focus-icon-1.png";
import squareIcon1WEBP from "../../shared/utils/images/focus-icon-1.webp";
import squareIcon1AVIF from "../../shared/utils/images/focus-icon-1.avif";
import squareIcon2 from "../../shared/utils/images/focus-icon-2.png";
import squareIcon2WEBP from "../../shared/utils/images/focus-icon-2.webp";
import squareIcon2AVIF from "../../shared/utils/images/focus-icon-2.avif";
import squareIcon3 from "../../shared/utils/images/focus-icon-3.png";
import squareIcon3WEBP from "../../shared/utils/images/focus-icon-3.webp";
import squareIcon3AVIF from "../../shared/utils/images/focus-icon-3.avif";

import "./css/Focus.css";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

export default () => {
  return (
    <div className="home-focus__section-container">
      {/* <img src={bg} className="home-focus__bg" /> */}
      <ImgWithFallback
        className="home-different-equation__bg-img"
        width={1920}
        height={1080}
        src={bgWEBP}
        avifSrc={bgAVIF}
        fallback={bg}
      />
      <Container fluid className="home-focus__container">
        <Parallax translateY={["50", "-50"]}>
          <div className="home-focus__wrapper">
            <Container>
              <h2 data-aos="fade-up">Therapeutic Areas of Focus</h2>
            </Container>
            <div className="home-focus__full-shape-container">
              <div className="home-focus__first-row-container">
                <div className="home-focus__part-container">
                  <Row className="home-focus__content-row home-focus__content-row-1">
                    <Col className="home-focus__square-text-col home-focus__square-text-col-1">
                      <div
                        className="home-focus__square-text-container"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <h3>Small Molecules</h3>
                        <h4>Main Areas of Interest</h4>
                        <p>
                          Hormones, Emergency and intensive care, Metabolic
                          disorders, Analgesics
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="home-focus__square-col"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="home-focus__diamond-square home-focus__diamond-square-1">
                        {/* <img
                        src={squareIcon1}
                        className="home-focus__diamond-square-icon"
                      /> */}
                        <ImgWithFallback
                          className="home-focus__diamond-square-icon"
                          width={1290}
                          height={942}
                          src={squareIcon1WEBP}
                          avifSrc={squareIcon1AVIF}
                          fallback={squareIcon1}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="home-focus__part-container">
                  <Row className="home-focus__content-row home-focus__content-row-2">
                    <Col
                      className="home-focus__square-col"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <div className="home-focus__diamond-square home-focus__diamond-square-2">
                        {/* <img
                        src={squareIcon2}
                        className="home-focus__diamond-square-icon"
                      /> */}
                        <ImgWithFallback
                          className="home-focus__diamond-square-icon"
                          width={1551}
                          height={1683}
                          src={squareIcon2WEBP}
                          avifSrc={squareIcon2AVIF}
                          fallback={squareIcon2}
                        />
                      </div>
                    </Col>
                    <Col className="home-focus__square-text-col home-focus__square-text-col-2">
                      <div
                        className="home-focus__square-text-container"
                        data-aos="fade-up"
                        data-aos-delay="500"
                      >
                        <h3>Opportunistic Areas</h3>
                        <h4>Main Areas of Interest</h4>
                        <p>
                          Antimicrobials, Nephrology, Urology, Respiratory,
                          Cardiovascular, Neurology, Rare Diseases
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="home-focus__second-row-container">
                <div className="home-focus__part-container home-focus__last-part-container">
                  <Row className="home-focus__content-row home-focus__content-row-3">
                    <Col
                      xs={6}
                      md={12}
                      className="home-focus__square-col home-focus__SR-square-col d-flex justify-content-center"
                      data-aos="fade-up"
                      data-aos-delay="600"
                      data-aos-offset="-200"
                    >
                      <div className="home-focus__diamond-square home-focus__diamond-square-3">
                        {/* <img
                        src={squareIcon3}
                        className="home-focus__diamond-square-icon"
                      /> */}
                        <ImgWithFallback
                          className="home-focus__diamond-square-icon"
                          width={1201}
                          height={2706}
                          src={squareIcon3WEBP}
                          avifSrc={squareIcon3AVIF}
                          fallback={squareIcon3}
                        />
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      md={12}
                      className="home-focus__square-text-col home-focus__square-text-col-3"
                    >
                      <div
                        className="home-focus__square-text-container"
                        data-aos="fade-up"
                        data-aos-delay="700"
                        data-aos-offset="-200"
                      >
                        <h3>Biologics</h3>
                        <h4>Main Areas of Interest</h4>
                        <p>
                          Hematology, Inflammatory disorders, Immunology,
                          Oncology
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </Container>
    </div>
  );
};
