import React from "react";

import { Col, Row } from "react-bootstrap";

import "./css/TeamMember.css";
import ImgWithFallback from "./ImgWithFallback";

export default (props) => {
  return (
    <Row className="ui-team-member__row">
      <Col xs={12} lg={4} className="ui-team-member__image-col">
        <div
          className="ui-team-member__image-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {/* <img src={props.img} /> */}
          <ImgWithFallback
            width={250}
            height={250}
            src={props.imgWEBP}
            avifSrc={props.imgAVIF}
            fallback={props.img}
          />
        </div>
      </Col>

      <Col
        xs={12}
        lg={8}
        className="ui-team-member__text-col"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <h3>{props.name}</h3>
        <h4>{props.position}</h4>
        <div className="ui-team-member__description-container">
          {props.description}
        </div>
      </Col>
    </Row>
  );
};
