import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import QuotationIcon from "../../shared/utils/icons/QuotationIcon";

import lightBulbIcon from "../../shared/utils/images/icons/light-bulb-icon.svg";
import eyeIcon from "../../shared/utils/images/icons/eye-icon.svg";

import bgIMG1 from "../../shared/utils/images/different-equation-bg-1.png";
import bgIMG1WEBP from "../../shared/utils/images/different-equation-bg-1.webp";
import bgIMG1AVIF from "../../shared/utils/images/different-equation-bg-1.avif";
import bgIMG2 from "../../shared/utils/images/different-equation-bg-2.png";
import bgIMG2WEBP from "../../shared/utils/images/different-equation-bg-2.webp";
import bgIMG2AVIF from "../../shared/utils/images/different-equation-bg-2.avif";
import bgBoxIMG from "../../shared/utils/images/different-equation-box-bg.png";
import bgBoxIMGWEBP from "../../shared/utils/images/different-equation-box-bg.webp";
import bgBoxIMGAVIF from "../../shared/utils/images/different-equation-box-bg.avif";

import "./css/DifferentEquation.css";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

export default () => {
  return (
    <div className="home-different-equation__section-container">
      <Container fluid className="home-different-equation__container">
        <Row className="home-different-equation__row">
          <Col xs={12} md={6} className="home-different-equation__col-1">
            {/* <img src={bgIMG1} className="home-different-equation__bg-img" /> */}
            <ImgWithFallback
              className="home-different-equation__bg-img"
              width={1036}
              height={1080}
              src={bgIMG1WEBP}
              avifSrc={bgIMG1AVIF}
              fallback={bgIMG1}
            />
            <Parallax translateY={["60", "-60"]}>

            
            <div
              className="home-different-equation__text-box"
              data-aos="flip-right"
              data-aos-delay="200"
            >
              {/* <img src={bgBoxIMG} className="home-different-equation__bg-img" /> */}
              <ImgWithFallback
                className="home-different-equation__bg-img"
                width={350}
                height={427}
                src={bgBoxIMGWEBP}
                avifSrc={bgBoxIMGAVIF}
                fallback={bgBoxIMG}
              />
              {/* <i className="home-different-equation__quote-icon fa-sharp fa-solid fa-quote-right"></i> */}
              <QuotationIcon
                className="home-different-equation__quote-icon"
                color="#fff"
              />
              <p>
                We are a community of developers combining science, knowledge,
                experience and technology innovation to deliver sustained
                outcomes and build trust.
              </p>
            </div>
            </Parallax>
          </Col>

          <Col xs={12} md={6} className="home-different-equation__col-2">
            {/* <img src={bgIMG2} className="home-different-equation__bg-img" /> */}
            <ImgWithFallback
              className="home-different-equation__bg-img"
              width={2030}
              height={2475}
              src={bgIMG2WEBP}
              avifSrc={bgIMG2AVIF}
              fallback={bgIMG2}
            />
            <div className="home-different-equation__col-2-container">
            <Parallax translateY={["-60", "60"]}>
              <h2 data-aos="fade-up">All Adds Up to Different Equation</h2>

              {/* <i className="fa-solid fa-lightbulb"></i> */}
              <div data-aos="fade-up" data-aos-delay="200">
                <img
                  src={lightBulbIcon}
                  className="home-different-equation__icon"
                />
                <h3>This different Equation is</h3>

                <p>
                  A community of developers working together in different ways
                  for innovative solutions.
                </p>
              </div>

              {/* <i className="fa-regular fa-eye"></i> */}
              <div data-aos="fade-up" data-aos-delay="200">
                <img src={eyeIcon} className="home-different-equation__icon" />
                <h3>Our Vision for Thabbat</h3>

                <p>
                  To be the most trusted and relevant Partner that combines the
                  most innovative solutions to deliver sustained outcomes and
                  build trust.
                </p>
              </div>
              </Parallax>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
