import React from "react";

import { Parallax } from "react-scroll-parallax";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./css/Footer.css";

const Footer = () => {
  return (
    <Parallax
      translateY={["0", "0"]}
      style={{ position: "relative", zIndex: 504 }}
    >
      <div className="footer py-4">
        <Container fluid>
          <Row>
            <Col xs={12} md={6} className="footer__col-1">
              <div className="footer__info">
                <h2>Contact Us</h2>
                <div className="footer__info-item">
                  <strong>Telephone:</strong> +966 11 253 5552
                </div>
                <div className="footer__info-item">
                  <strong>Address:</strong> Al Hassan Bin Ali Street, Building
                  12A Riyadh, Saudi Arabia
                </div>
                <div className="footer__info-item">
                  <strong>Email:</strong>{" "}
                  <a href="mailto:info@thabbat.com">info@Thabbat.com</a>
                </div>
              </div>
            </Col>

            {/* <Col xs={12} md={6}>
              <div>
                <iframe
                  className="footer__location-map"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?hl=en&amp;q=24.7368438,46.6435728+(Thabbat)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                />
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col className="d-flex align-items-end">
              <div className="footer__copyright">
                <i>Copyright © 2022 Thabbat</i>
              </div>
            </Col>

            <Col className="d-flex align-items-end justify-content-end">
              <div className="footer__powered-by">
                <i>
                  Powered by{" "}
                  <a
                    className="footer__powered-by-link"
                    href="https://inssain.co"
                    target="_blank"
                  >
                    INSSAIN
                  </a>
                </i>
              </div>
            </Col>

            {/* <Col>
              <div className="footer__links">
                <a
                  className="btn btn-dark btn-social mx-2"
                  href="./#"
                  aria-label="WhatsApp"
                  target="_blank"
                >
                  <i className="fa-brands fa-whatsapp fa-xl"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href="./#"
                  aria-label="Twitter"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href="./#"
                  aria-label="Instagram"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href="./#"
                  aria-label="LinkedIn"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </Parallax>
  );
};

export default Footer;
