import React from "react";

export default (props) => {
  return (
    <svg
      width="81"
      height="80"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      space="preserve"
      overflow="hidden"
      className={props.className}
    >
      <defs>
        <clipPath id="clip0">
          <rect x="56" y="246" width="81" height="80" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0)" transform="translate(-56 -246)">
        <path
          d="M0 36.5625 20.9375 36.5625 20.9375 42.6703C20.9375 46.1597 18.0988 48.9984 14.6094 48.9984L6.5625 48.9984 6.5625 64.6234 14.6094 64.6234C26.7145 64.6234 36.5625 54.7753 36.5625 42.6703L36.5625-8.88178e-15 0-8.88178e-15Z"
          fill={props.color || "#00B0F0"}
          transform="matrix(1.0125 0 0 1 56 253.656)"
        />
        <path
          d="M43.4375 0 43.4375 36.5625 64.375 36.5625 64.375 42.6703C64.375 46.1597 61.5363 48.9984 58.0469 48.9984L50 48.9984 50 64.6234 58.0469 64.6234C70.152 64.6234 80 54.7753 80 42.6703L80-8.88178e-15Z"
          fill={props.color || "#00B0F0"}
          transform="matrix(1.0125 0 0 1 56 253.656)"
        />
      </g>
    </svg>
  );
};
