import React from "react";
import Nav from "react-bootstrap/Nav";

import "./css/NavLinks.css";

const NavLinks = (props) => {
  return (
    <React.Fragment>
      <Nav.Link onClick={props.closeNav} href="./#about">
        About Us
      </Nav.Link>
      <Nav.Link onClick={props.closeNav} href={"./#focus"}>
        Our Focus
      </Nav.Link>
      <Nav.Link onClick={props.closeNav} href={"./#partnership"}>
        Partnership
      </Nav.Link>
      <Nav.Link onClick={props.closeNav} href={"./#supportive-strengths"}>
        Supportive Strengths
      </Nav.Link>
      <Nav.Link onClick={props.closeNav} href={"./#team"}>
        Our Team
      </Nav.Link>
    </React.Fragment>
  );
};

export default NavLinks;
