import React, { useEffect } from "react";
import { Section as ScrollSection } from "react-scroll-section";
import { Parallax } from "react-scroll-parallax";
// import { useLocation } from "react-router-dom";
// import $ from "jquery";
// import "jquery-scrollify";

import AOS from "aos";
import "aos/dist/aos.css";

import Top from "../components/Top";
import About from "../components/About";
import Partners from "../components/Partners";
import DifferentEquation from "../components/DifferentEquation";
import Solutions from "../components/Solutions";
// import Focus from "../components/Focus";
import Focus from "../components/Focus";
// import Partnership from "../components/Partnership";
import SuccessfulPartnership from "../components/SuccessfulPartnership";
import Services from "../components/Services";
import Team from "../components/Team";

import "./css/Home.css";

const Home = () => {
  // const location = useLocation();
  // const partnersSection = useScrollSection("partners");

  useEffect(() => {
    AOS.init({
      offset: 80,
    });

    // if (location.hash) {
    //   let elem = location.hash.slice(1);
    //   switch (elem.toLowerCase()) {
    //     case "partners":
    //       partnersSection.onClick();
    //       break;
    //     default:
    //   }
    // }

    // $.scrollify({
    //   section: ".home-section-scroll",
    //   // offset: 0,
    //   // scrollbars: false,
    //   scrollSpeed: 500,
    // });
  }, []);

  return (
    <React.Fragment>
      <Parallax translateY={["-200px", "200px"]}>
        <ScrollSection id="top">
          <Top />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["0px", "150px"]}
        style={{ position: "relative", zIndex: 501 }}
      >
        <ScrollSection id="about">
          <About />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["0", "0"]}
        style={{ position: "relative", zIndex: 503 }}
      >
        <ScrollSection id="partners">
          <Partners />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["-150px", "150px"]}
        style={{ position: "relative", zIndex: 502 }}
      >
        <ScrollSection id="different-equation">
          <DifferentEquation />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["0", "0"]}
        style={{ position: "relative", zIndex: 503 }}
      >
        <ScrollSection id="solutions">
          <Solutions />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["-75px", "100px"]}
        style={{ position: "relative", zIndex: 502 }}
      >
        <ScrollSection id="focus">
          <Focus />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["0", "0"]}
        style={{ position: "relative", zIndex: 503 }}
      >
        <ScrollSection id="partnership">
          <SuccessfulPartnership />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["-120px", "150px"]}
        style={{ position: "relative", zIndex: 502 }}
      >
        <ScrollSection id="supportive-strengths">
          <Services />
        </ScrollSection>
      </Parallax>

      <Parallax
        translateY={["-25px", "100px"]}
        style={{ position: "relative", zIndex: 503 }}
      >
        <ScrollSection id="team">
          <Team />
        </ScrollSection>
      </Parallax>
      {/* <Parallax translateY={["-30", "30"]}>
        <ScrollSection id="top">
          <Top />
        </ScrollSection>
      </Parallax>

      <Parallax translateY={["0", "25"]} style={{position: "relative", zIndex: 502}}>
          <ScrollSection id="about">
            <About />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["0", "0"]} style={{position: "relative", zIndex: 503}}>
          <ScrollSection id="partners">
            <Partners />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["-20", "20"]} style={{position: "relative", zIndex: 501}}>
          <ScrollSection id="different-equation">
            <DifferentEquation />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["0", "0"]} style={{position: "relative", zIndex: 503}}>
          <ScrollSection id="solutions">
            <Solutions />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["-10", "15"]} style={{position: "relative", zIndex: 502}}>
          <ScrollSection id="focus">
            <Focus />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["0", "0"]} style={{position: "relative", zIndex: 503}}>
          <ScrollSection id="partnership">
            <SuccessfulPartnership />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["-20", "20"]} style={{position: "relative", zIndex: 502}}>
          <ScrollSection id="supportive-strengths">
            <Services />
          </ScrollSection>
        </Parallax>

        <Parallax translateY={["0px", "100px"]} style={{position: "relative", zIndex: 503}}>
          <ScrollSection id="team">
            <Team />
          </ScrollSection>
        </Parallax> */}
    </React.Fragment>
  );
};

export default Home;
