import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Col } from "react-bootstrap";

import "./css/ServiceCard.css";
import ImgWithFallback from "./ImgWithFallback";

export default (props) => {
  const reverseSign = props.itemNumber % 2 === 0 ? 1 : -1;
  const translate = [reverseSign * -50, reverseSign * 50];

  const parallaxProps = {
    translateY:
      window.innerWidth >= 992
        ? translate
        : null,
    translateX: window.innerWidth < 992 ? translate : null,
  };
//translateY={[reverseSign * -50, reverseSign * 50]}
  return (
    <Col
      xs={12}
      // md={12}
      lg={props.colMDSize || 3}
      className="ui-service-card__col"
    >
      <Parallax {...parallaxProps}>
        <div
          className={`ui-service-card__container ${props.className}`}
          data-aos="flip-left"
          data-aos-delay={100 * (props.itemNumber || 1)}
        >
          {/* <img src={props.bgIMG} className="ui-service-card__bg-img" /> */}
          <ImgWithFallback
            className="ui-service-card__bg-img"
            width={273}
            height={512}
            src={props.bgIMGWEBP}
            avifSrc={props.bgIMGAVIF}
            fallback={props.bgIMG}
          />
          <div className="ui-service-card__icon-container">
            <img src={props.icon} alt="Icon" width={48} height={48} />
          </div>
          <div className="ui-service-card__content-container">
            <h3>{props.title}</h3>
            <ul className="ui-service-card__points-list">{props.points}</ul>
          </div>
        </div>
      </Parallax>
    </Col>
  );
};
