import React from "react";
import { Parallax } from "react-scroll-parallax";

import { Container } from "react-bootstrap";
import TeamMember from "../../shared/components/UIElements/TeamMember";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

import bg from "../../shared/utils/images/solutions-bg.jpg";
import bgWEBP from "../../shared/utils/images/solutions-bg.webp";
import bgAVIF from "../../shared/utils/images/solutions-bg.avif";

import teamIMG1 from "../../shared/utils/images/team/team-1.png";
import teamIMG1WEBP from "../../shared/utils/images/team/team-1.webp";
import teamIMG1AVIF from "../../shared/utils/images/team/team-1.avif";
import teamIMG2 from "../../shared/utils/images/team/team-2.png";
import teamIMG2WEBP from "../../shared/utils/images/team/team-2.webp";
import teamIMG2AVIF from "../../shared/utils/images/team/team-2.avif";
import teamIMG3 from "../../shared/utils/images/team/team-3.jpg";
import teamIMG3WEBP from "../../shared/utils/images/team/team-3.webp";
import teamIMG3AVIF from "../../shared/utils/images/team/team-3.avif";
import teamIMG4 from "../../shared/utils/images/team/team-4.png";
import teamIMG4WEBP from "../../shared/utils/images/team/team-4.webp";
import teamIMG4AVIF from "../../shared/utils/images/team/team-4.avif";
import teamIMG5 from "../../shared/utils/images/team/team-5.png";
import teamIMG5WEBP from "../../shared/utils/images/team/team-5.webp";
import teamIMG5AVIF from "../../shared/utils/images/team/team-5.avif";
import teamIMG6 from "../../shared/utils/images/team/team-6.jpg";
import teamIMG6WEBP from "../../shared/utils/images/team/team-6.webp";
import teamIMG6AVIF from "../../shared/utils/images/team/team-6.avif";

import "./css/Team.css";

export default () => {
  return (
    <div className="home-team__section-container ">
      {/* <img src={bg} className="home-team__bg-img" /> */}
      <ImgWithFallback
        className="home-team__bg-img"
        width={2249}
        height={1265}
        src={bgWEBP}
        avifSrc={bgAVIF}
        fallback={bg}
      />
      <Container className="home-team__container">
      {/* <Parallax translateY={["10", "-10"]}> */}
        <div className="home-team__wrapper">
          <h2 data-aos="fade-up">Our Team</h2>

          <TeamMember
            name="Mubarak Al Hurasen"
            img={teamIMG1}
            imgWEBP={teamIMG1WEBP}
            imgAVIF={teamIMG1AVIF}
            position="Founder & CEO"
            description={`Mubarak has more than 25 years of experience working in
various Sales, Marketing and Business Development
positions for healthcare companies in Saudi Arabia.
He served on many Pharmaceutical Companies to
developed strategic partnerships especially in the
Biopharmaceutical Industries (Plasma Derivatives and
Biosimilars). He developed extensive pharmaceutical
industry experience with wide international network.`}
          />

          <TeamMember
            name="Sabah Al Rayes"
            img={teamIMG2}
            imgWEBP={teamIMG2WEBP}
            imgAVIF={teamIMG2AVIF}
            position="Partner and Strategic Advisory"
            description={
              <React.Fragment>
                <p>
                  Prof. Sabah is the General Manager of general directorate of
                  pharmaceutical &amp; medical licenses, Ministry of Health
                  (From 1998 to 2004)
                </p>

                <p>
                  Then he worked with many leading pharmaceutical companies in
                  MENA market. He is Member in several committees and
                  professional boards inside Saudi Arabia and abroad, like:
                </p>

                <ul>
                  <li>
                    Head of preliminary committee for pharmaceutical companies
                    and product registration.
                  </li>

                  <li>
                    Head of the inspection team on industrial plants to ensure
                    the correct application of proper manufacturing practice.
                  </li>
                  <li>
                    Pharmaceutical companies and product registration committee.
                  </li>
                  <li>
                    Gulf Committee for Pharmaceuticals Registration – Executive
                    Office for GCC.
                  </li>
                  <li>
                    Member of Arab Pharmaceuticals Committee – Representing
                    Saudi Arabia in Arab League
                  </li>
                  <li>
                    Member of the high Committee for Quality Assurance headed by
                    the Minister of Health.
                  </li>
                  <li>Member of the OTC Directory Development Committee.</li>
                </ul>
              </React.Fragment>
            }
          />

          <TeamMember
            name="Khalid M. Alkharfy"
            img={teamIMG3}
            imgWEBP={teamIMG3WEBP}
            imgAVIF={teamIMG3AVIF}
            position="Pharma/BioPharma Advisory"
            description={`Dr Khalid is Professor of clinical and pharmaceutical sciences. He had
enormous contributions to the health sector research and innovation.
He served on many committees with the Ministry of Health and Saudi
Food and Drug Authority, and developed extensive pharmaceutical
industry experience and consultancy. He also developed a wide
international network of KOLs and business decision makers.`}
          />

          <TeamMember
            name="Ahmad Elkholy"
            img={teamIMG4}
            imgWEBP={teamIMG4WEBP}
            imgAVIF={teamIMG4AVIF}
            position="Regional RA Manger"
            description={`Ahmad has more than 12 years of experience working in Pharmaceutical and
Biopharmaceuticals. Medical Device Regulatory Affairs Experience in Egypt
and Saudi Arabia. Ahmad holds a B.Sc. Pharmaceutical Science, from Cairo
University in Egypt & MBA from The American University, London, UK. He
is acting as the Lead on registration topics, products&#39; life cycle, &
Regulatory Compliance matters for all products&#39; Marketing
Authorizations (MAs) for GCC, he is responsible for define and provide
regulatory strategy for products in development and business opportunities.`}
          />

          <TeamMember
            name="Manfred Stadler"
            img={teamIMG5}
            imgWEBP={teamIMG5WEBP}
            imgAVIF={teamIMG5AVIF}
            position="General Transaction Advisory"
            description={`Manfred has M&A, Management, Finance and Controlling expertise; Life
Sciences and API Experience in Corporate Strategy and M&A, Head of BD
and CFO roles. He has lived and worked in various countries (US, UK, CH, D, A)
and industries (Pharma, Chemical, Communications) and gathered both,
management and consulting experience and skills. His deep understanding of
the industry, of valuing and integrating businesses, makes him a valuable
partner in transaction and transformation.`}
          />

          <TeamMember
            name="Hassn Jibreel"
            img={teamIMG6}
            imgWEBP={teamIMG6WEBP}
            imgAVIF={teamIMG6AVIF}
            position="Technical Advisory"
            description={`He is a Pharmaceutical Operations Professional with 30 years of experience
in the Pharmaceutical Industry. He worked with many leading pharmaceutical
companies in MENA and has Excellent knowledge and experience with processes
involved in quality analytical laboratories, validation, manufacturing, quality
assurance, process development and distribution. Strong history of contributing to
company growth and product launches by implementing continuous process
improvement and process optimizations. Vast knowledge in GMP and GLP
regulations and experience in regulatory agencies inspections and audits.`}
          />
        </div>
        {/* </Parallax> */}
      </Container>
    </div>
  );
};
