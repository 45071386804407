import React from "react";

const ImgWithFallback = ({
  src,
  avifSrc,
  fallback,
  type = "image/webp",
  alt,
  ...delegated
}) => {
  return (
    <picture {...delegated}>
      <source srcSet={src} type={type} />
      {avifSrc && <source srcSet={avifSrc} type="image/avif" />}
      <img src={fallback} alt={alt || "Image"} {...delegated} />
    </picture>
  );
};

export default ImgWithFallback;
