import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import NavLinks from "./NavLinks";
import "./css/MainNavigation.css";
import logoImage from "../../utils/images/logo.svg";

const MainNavigation = () => {
  let location = useLocation().pathname;
  const [isCollapsed, setCollapsed] = useState(true);
  const togglerRef = useRef(null);

  const closeNav = () => {
    setCollapsed(true);
    var button = document.getElementById("menu-button");
    button.classList.remove("button-change");
  };

  useEffect(() => {
    const navbarCollapsible = document.body.querySelector("#mainNav");
    const togglerElement = togglerRef.current;

    var navbarShrink = function() {
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove("navbar-shrink");
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    };

    if (!navbarCollapsible.classList.contains("static")) {
      navbarShrink();
      document.addEventListener("scroll", navbarShrink);
    }

    function toggleMenu() {
      var button = document.getElementById("menu-button");
      button.classList.toggle("button-change");

      setCollapsed(!button.classList.contains("button-change"));
    }

    togglerElement.addEventListener("click", toggleMenu);

    return () => {
      togglerElement.removeEventListener("click", toggleMenu);
    };
  }, []);

  return (
    <Navbar
      className={location !== "/" ? "navbar-shrink static" : ""}
      id="mainNav"
      collapseOnSelect
      expand="lg"
      fixed="top"
      expanded={!isCollapsed}
    >
      <Container>
        <Navbar.Brand href="./">
          <img width={1380} height={506} src={logoImage} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <div id="menu-button" ref={togglerRef}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          // className={!isCollapsed ? "show" : ""}
          // className="show"
        >
          <Nav>
            <NavLinks closeNav={closeNav} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavigation;
