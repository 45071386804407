import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "react-bootstrap";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "./css/Partners.css";

import partner1 from "../../shared/utils/images/partners/aet.png";
import partner2 from "../../shared/utils/images/partners/abc-farmaceutici.png";
import partner3 from "../../shared/utils/images/partners/aizant.png";
import partner4 from "../../shared/utils/images/partners/biotest.png";
import partner5 from "../../shared/utils/images/partners/dr-reddys.png";
import partner6 from "../../shared/utils/images/partners/gennisium.png";
import partner7 from "../../shared/utils/images/partners/hetero-biopharma.png";
import partner8 from "../../shared/utils/images/partners/inventia.png";
import partner9 from "../../shared/utils/images/partners/maypro.png";
import partner10 from "../../shared/utils/images/partners/pharma-ppi.png";
import partner11 from "../../shared/utils/images/partners/pharma-science.png";
import partner12 from "../../shared/utils/images/partners/piramal.png";
import partner13 from "../../shared/utils/images/partners/s&a.png";
import partner14 from "../../shared/utils/images/partners/steril-gene.png";
import partner15 from "../../shared/utils/images/partners/velit.png";
import partner16 from "../../shared/utils/images/partners/vianex.png";
import partner17 from "../../shared/utils/images/partners/vinc.png";
import partner18 from "../../shared/utils/images/partners/wockhardt.png";
import partner19 from "../../shared/utils/images/partners/Picture23.png";

SwiperCore.use([Autoplay]);

const Partners = () => {
  return (
    <div className="home-partners__section-container">
      <Container className="home-partners__container">
        <h2 className="home-partners__heading">Our Key Partners</h2>
        <h3 className="home-partners__sub-heading">
          Strategic & Manufacturing Partners
        </h3>

        <Swiper
          centeredSlides={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={25}
          slidesPerView={3}
          speed={500}
          loop={true}
          breakpoints={{
            576: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 6,
            },
            992: {
              slidesPerView: 8,
            },
          }}
          className="home-partners__carousel"
        >
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner1}
              width={80}
              height={59}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner2}
              width={80}
              height={51}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner3}
              width={80}
              height={26}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner4}
              width={80}
              height={21}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner5}
              width={80}
              height={18}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner6}
              width={80}
              height={19}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner7}
              width={80}
              height={32}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner8}
              width={80}
              height={21}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner9}
              width={80}
              height={19}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner10}
              width={80}
              height={50}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner11}
              width={80}
              height={31}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner12}
              width={80}
              height={40}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo home-partners__partner-logo-sm"
              src={partner13}
              width={80}
              height={66}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner19}
              width={80}
              height={70}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner14}
              width={80}
              height={51}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner15}
              width={80}
              height={33}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner16}
              width={80}
              height={29}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner17}
              width={80}
              height={26}
            />
          </SwiperSlide>
          <SwiperSlide className="home-partners__slider-item">
            <img
              className="home-partners__partner-logo"
              src={partner18}
              width={80}
              height={18}
            />
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
};

export default Partners;
