import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Col, Container, Row } from "react-bootstrap";

import bg from "../../shared/utils/images/solutions-bg.jpg";
import bgWEBP from "../../shared/utils/images/solutions-bg.webp";
import bgAVIF from "../../shared/utils/images/solutions-bg.avif";
import specturmIMG from "../../shared/utils/images/spectrum.png";
import specturmIMGWEBP from "../../shared/utils/images/spectrum.webp";
import specturmIMGAVIF from "../../shared/utils/images/spectrum.avif";
import icon1 from "../../shared/utils/images/icons/solutions-icon-1.svg";
import icon2 from "../../shared/utils/images/icons/solutions-icon-2.svg";
import icon3 from "../../shared/utils/images/icons/solutions-icon-3.svg";
import icon4 from "../../shared/utils/images/icons/solutions-icon-4.svg";

import "./css/Solutions.css";
import ImgWithFallback from "../../shared/components/UIElements/ImgWithFallback";

export default () => {
  return (
    <div className="home-solutions__section-container">
      {/* <img src={bg} className="home-solutions__bg-img" /> */}
      <ImgWithFallback
        className="home-solutions__bg-img"
        width={1920}
        height={1080}
        src={bgWEBP}
        avifSrc={bgAVIF}
        fallback={bg}
      />
      <Container className="home-solutions__container">
        <Parallax translateY={["-20", "20"]}>
          <div className="home-solutions__wrapper">
            <h2 data-aos="fade-up">A Spectrum of Pharmaceutical Solutions</h2>

            <Row className="home-solutions__row">
              {/* <img
              src={specturmIMG}
              className="home-solutions__row-bg-img"
              data-aos="fade-up"
              data-aos-delay="200"
            /> */}
              <ImgWithFallback
                className="home-solutions__row-bg-img"
                width={1224}
                height={422}
                src={specturmIMGWEBP}
                avifSrc={specturmIMGAVIF}
                fallback={specturmIMG}
                data-aos="fade-up"
                data-aos-delay="200"
              />
              <Row className="home-solutions__inner-first-row">
                <Col xs={8} className="home-solutions__text-col-1">
                  <div
                    className="home-solutions__text-container"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <img src={icon1} className="home-solutions__icon" />
                    <h4>Discovering & Defining the Need</h4>
                  </div>
                  <div
                    className="home-solutions__line"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="home-solutions__line-text-point"></div>
                    <div className="home-solutions__line-link-point"></div>
                  </div>
                </Col>
                {/* <Col xs={3}></Col> */}
                <Col xs={4} className="home-solutions__text-col-2">
                  <div
                    className="home-solutions__text-container"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <img src={icon2} className="home-solutions__icon" />
                    <h4>Product Delivery & Management</h4>
                  </div>
                  <div
                    className="home-solutions__line"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <div className="home-solutions__line-text-point"></div>
                    <div className="home-solutions__line-link-point"></div>
                  </div>
                </Col>
              </Row>
              <Row className="home-solutions__inner-second-row">
                <Col xs={4}></Col>
                <Col xs={6} className="home-solutions__text-col-3">
                  <div
                    className="home-solutions__line"
                    data-aos="fade-up"
                    data-aos-delay="700"
                    data-aos-offset="-200"
                  >
                    <div className="home-solutions__line-link-point"></div>
                    <div className="home-solutions__line-text-point"></div>
                  </div>

                  <div
                    className="home-solutions__text-container"
                    data-aos="fade-up"
                    data-aos-delay="800"
                    data-aos-offset="-200"
                  >
                    <img src={icon3} className="home-solutions__icon" />
                    <h4>Product Design & Development</h4>
                  </div>
                </Col>
                <Col xs={2} className="home-solutions__text-col-4">
                  <div
                    className="home-solutions__line"
                    data-aos="fade-up"
                    data-aos-delay="900"
                    data-aos-offset="-200"
                  >
                    <div className="home-solutions__line-link-point"></div>
                    <div className="home-solutions__line-text-point"></div>
                  </div>

                  <div
                    className="home-solutions__text-container"
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-offset="-200"
                  >
                    <img src={icon4} className="home-solutions__icon" />
                    <h4>World Wide Security of the Product</h4>
                  </div>
                </Col>
              </Row>
            </Row>

            {/* <img className="home-solutions__spectrum-img" src={specturmIMG} /> */}
          </div>
        </Parallax>
      </Container>
    </div>
  );
};
